import React from "react"
import { graphql } from "gatsby"
import rehypeReact from "rehype-react"
import { Box, Flex } from "rebass"
import {
  ArticleGrid,
  BlogA,
  BlogBlockquote,
  BlogCode,
  BlogCodeSpan,
  BlogH1,
  BlogH2,
  BlogH3,
  BlogH4,
  BlogHr,
  BlogImg,
  BlogLi,
  BlogOl,
  BlogP,
  BlogUl,
  BlogPre,
  BlogSup,
  Button,
  CssExample,
  Pointer,
} from "../components/styles"
import Layout from "../components/layout"
import SEO from "../components/seo"

const BlogPostTemplate = ({ data, pageContext, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata.title
  const { previous, next } = pageContext

  const parseWithStyledComponents = new rehypeReact({
    createElement: React.createElement,
    Fragment: React.Fragment,
    components: {
      a: BlogA,
      h1: BlogH1,
      h2: BlogH2,
      h3: BlogH3,
      h4: BlogH4,
      p: BlogP,
      blockquote: BlogBlockquote,
      ul: BlogUl,
      ol: BlogOl,
      li: BlogLi,
      pre: BlogPre,
      code: BlogCode,
      span: BlogCodeSpan,
      img: BlogImg,
      hr: BlogHr,
      sup: BlogSup,
      "css-example": CssExample,
    },
  }).Compiler

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <Flex justifyContent="center">
        <ArticleGrid>
          {parseWithStyledComponents(post.htmlAst)}
          <BlogHr />
        </ArticleGrid>
      </Flex>

      <nav>
        <Flex
          justifyContent="space-between"
          flexWrap="wrap"
          alignItems="center"
          alignContent="center"
          mx={5}
          pb={4}
        >
          {previous && (
            <Box my={2} mx="auto">
              <Button>
                <Pointer to={previous.fields.slug} rel="prev">
                  ← {previous.frontmatter.title}
                </Pointer>
              </Button>
            </Box>
          )}
          {next && (
            <Box my={2} mx="auto">
              <Button>
                <Pointer to={next.fields.slug} rel="next">
                  {next.frontmatter.title} →
                </Pointer>
              </Button>
            </Box>
          )}
        </Flex>
      </nav>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      htmlAst
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
      }
    }
  }
`
